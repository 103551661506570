import React, { useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Nav, Row } from 'react-bootstrap';
import CreateProductStep1 from 'pages/Artwoks/CreateProduct/CreateProductStep1';
import axios from 'axios';
import CategoriesStep2 from 'pages/Artwoks/Categories/CategoriesStep2';
import SeoStep3 from 'pages/Artwoks/Seo';
import SupportingImageStep4 from 'pages/Artwoks/SupportingImages';
import SuccessModal from './succesFullmodal';
import { useNavigate } from 'react-router-dom';

const getValidationSchema = (step: number) => {
  switch (step) {
    case 0:
      return Yup.object().shape({
        // Title: Yup.string().required('Title is required'),
        // Dimension_width: Yup.string().required('Dimension width is required'),
        // Dimension_height: Yup.string().required('Dimension height is required'),
        // Orientation: Yup.string().required('Orientation is required'),
        // Artwork_image: Yup.mixed().required('Artwork image is required'),
        // Artwork_full_image: Yup.mixed().required('Artwork full image is required'),
      });
    case 1:
      return Yup.object().shape({
        // shipped_as: Yup.string().required('Shipped as is required'),
        // category_id: Yup.string().required('Category is required'),
      });
    case 2:
      return Yup.object().shape({
        // seo_title: Yup.string().required('Seo title is required'),
      });
    case 3:
      return Yup.object().shape({
        // supporting_image_1: Yup.mixed().required('Supporting image 1 is required'),
      });
    default:
      return Yup.object().shape({});
  }
};

const steps = ['Artwork', 'Categories/Tags', 'Seo', 'Supporting Images'];

interface StepContentProps {
  step: number;
  formik: FormikProps<any>;
}


const StepperWizard: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [artWorkId, setArtWorkId] = useState<any>()
  const [artWorkdata, setArtWorkdata] = useState<any>()
  
  const [artWorktitle, setArtWorktitle] = useState<any>()
  

  const [artWorkdescription, setArtWorkDescription] = useState<any>()
  const [artWorkmediums_id, setArtWorkmediums_id] = useState<any>()
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const navigate = useNavigate();

  const StepContent: React.FC<StepContentProps> = ({ step, formik }) => {
    switch (step) {
      case 0:
        return (
          <CreateProductStep1 formik={formik} />
        );
      case 1:
        return (
          <CategoriesStep2 formik={formik} artWorkdata={artWorkdata} />
          
        );
      case 2:
        return (
          <SeoStep3 formik={formik} artWorkdata={artWorkdata} artWorkmediums_id={artWorkmediums_id} artWorktitle={artWorktitle} artWorkdescription={artWorkdescription}/>
        );
      case 3:
        return (
          <SupportingImageStep4 formik={formik} />
        );
      default:
        return null;
    }
  };
  

  // console.log("rr", artWorkId)

  const getInitialValues = () => {
    switch (step) {
      case 0:
        return {
          artist_id: '',
          Title: '',
          Slug: '',
          Label: '',
          Description: '',
          Status: '',
          sold_out: '',
          Weight: '',
          Dimension_depth: '',
          Originals_price: '',
          Originals_price_dollar: '',
          Year: '',
          Dimension_width: '',
          Is_available_for_print_sale: '',
          Month: '',
          Dimension_height: '',
          Limited_period_discount: '',
          Is_vailable_for_desktop_art: '',
          Creation_place: '',
          Orientation: '',
          Price_on_request: '',
          Label_for_date: '',
          Artwork_image: null,
          Artwork_webp_image: null,
          Artwork_full_image: null,
          Artwork_full_webp_image: null,
          artwork_id: '',
        
        };
      case 1:
        return {
          surface: '',
          category_id: '',
          art_subject: '',
          art_style: '',
          Mediums_id: '',
          art_type: '',
          palette: '',
          tags: '',
          signature: '',
          edition_size: '',
          timelines: '',
          year_released: '',
          colors: '',
          shipped_as: '',
          artwork_id: '',
          artWorkdata: artWorkdata,
        };
      case 2:
        return {
          seo_title: '',
          seo_keyword: '',
          seo_description: '',
          seo_image: null,
          artwork_id: '',
        };
      case 3:
        return {
          supporting_image_1: null,
          supporting_image_2: null,
          supporting_image_3: null,
          supporting_image_4: null,
          supporting_image_5: null,
          supporting_image_6: null,
          supporting_image_7: null,
          supporting_image_8: null,
          supporting_image_9: null,
          supporting_image_10: null,
          supporting_image_11: null,
          artwork_id: '',
        };
      default:
        return {};
    }
  };
  const getFilteredValues = (values: any) => {
    switch (step) {
      case 0:
        return {
          artist_id: values.artist_id,
          Title: values.Title,
          Slug: values.Slug,
          Label: values.Label,
          Description: values.Description,
          Status: values.Status,
          sold_out: values.sold_out,
          Weight: values.Weight,
          Dimension_depth: values.Dimension_depth,
          Originals_price: values.Originals_price,
          Originals_price_dollar: values.Originals_price_dollar,
          Year: values.Year,
          Dimension_width: values.Dimension_width,
          Is_available_for_print_sale: values.Is_available_for_print_sale,
          Month: values.Month,
          Dimension_height: values.Dimension_height,
          Limited_period_discount: values.Limited_period_discount,
          Is_vailable_for_desktop_art: values.Is_vailable_for_desktop_art,
          Creation_place: values.Creation_place,
          Orientation: values.Orientation,
          Price_on_request: values.Price_on_request,
          Label_for_date: values.Label_for_date,
          Artwork_image: values.Artwork_image,
          Artwork_webp_image: values.Artwork_webp_image,
          Artwork_full_image: values.Artwork_full_image,
          Artwork_full_webp_image: values.Artwork_full_webp_image,
        };
      case 1:
        return {
          surface: values.surface,
          category_id: values.category_id,
          art_subject: values.art_subject,
          art_style: values.art_style,
          Mediums_id: values.Mediums_id,
          art_type: values.art_type,
          palette: values.palette,
          tags: values.tags,
          signature: values.signature,
          edition_size: values.edition_size,
          timelines: values.timelines,
          year_released: values.year_released,
          colors: values.colors,
          shipped_as: values.shipped_as,
          artwork_id: artWorkId,
        };
      case 2:
        return {
          seo_title: values.seo_title,
          seo_keyword: values.seo_keyword,
          seo_description: values.seo_description,
          seo_image: values.seo_image,
          artwork_id: artWorkId,
        };
      case 3:
        return {
          supporting_image_1: values.supporting_image_1,
          supporting_image_2: values.supporting_image_2,
          supporting_image_3: values.supporting_image_3,
          supporting_image_4: values.supporting_image_4,
          supporting_image_5: values.supporting_image_5,
          supporting_image_6: values.supporting_image_6,
          supporting_image_7: values.supporting_image_7,
          supporting_image_8: values.supporting_image_8,
          supporting_image_9: values.supporting_image_9,
          supporting_image_10: values.supporting_image_10,
          supporting_image_11: values.supporting_image_11,
          artwork_id: artWorkId,
        };
      default:
        return {};
    }
  };

  const handleNext = async (formikProps: FormikProps<any>) => {
    try {
      const errors = await formikProps.validateForm();

      if (Object.keys(errors).length === 0) {
        await formikProps.submitForm()
        if (step < steps.length - 1) {
          setStep(step + 1);
          setCompletedSteps([...completedSteps, step]);
        }
        else {
          setCompletedSteps([...completedSteps, step]);
          formikProps.resetForm();
          setShowSuccessModal(true);
        }
      } else {
        formikProps.setTouched(
          Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: true }), {})
        );
      }
    } catch (error) {
      console.error('Error during validation:', error);
    }
  };

  const handleBack = () => setStep(step - 1);

  const handleModalClose = () => {
    setShowSuccessModal(false);
    navigate('/dashboard');
  };

  return (
    <Row>
      <Col md={3}>
        <Nav className="flex-column">
          {steps.map((label, index) => (
            <Nav.Item key={index} style={{ borderBottom: '1px solid #ddd' }}>
              <Nav.Link
                active={index === step}
                onClick={() => setStep(index)}
                disabled={index > Math.max(...completedSteps, step)}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <span style={{ marginRight: '8px' }}>
                  {completedSteps.includes(index) ? '✔' : '✖'}
                </span>
                Step {index + 1}: {label}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Col>
      <Col md={9}>
        <Formik
          initialValues={getInitialValues()}
          validationSchema={getValidationSchema(step)}
          onSubmit={async (values, { resetForm }: any) => {
            try {
              const tokenString = localStorage.getItem('authUser');
              const token = tokenString ? JSON.parse(tokenString)?.token : '';

              const endpoint =
                step === 0
                  ? '/auth/add_artworklist'
                  : step === 1
                    ? '/auth/Add_categories'
                 
                    : step === 2
                      ? '/auth/Add_Seo'
                      : '/auth/add_Supporting';

              const filteredValues = getFilteredValues(values);

              const response = await axios.post(
                `${process.env.REACT_APP_API_BASEURL}${endpoint}`,
                filteredValues,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
       
        
              if (response) {
                if (response && response.data && response.data.user) {
                  console.log(response.data.user, "response.data.user.idresponse.data.user.idresponse.data.user.idresponse.data.user.id" )
                  setArtWorkId(response.data.user.id);
              
                  resetForm()
                }
                if(response.data.user.Artist_id){
                  setArtWorkdata( response.data.user.Artist_id)
                }
                if(response.data.user.Mediums_id){
                  setArtWorkmediums_id( response.data.user.Mediums_id)
                }
                if(response.data.user.Title){
                  setArtWorktitle( response.data.user.Title)
                }
                if(response.data.user.Description){
                  setArtWorkDescription( response.data.user.Description)
                }
              } else {
                throw new Error('something went wrong')
              }
              resetForm();
            } catch (error) {
              console.error('Error submitting form:', error);
              throw new Error('something went wrong')
            }
          }}
        >
          {(formik) => (
            <Form>
              <StepContent step={step} formik={formik} />
              <div className="d-flex justify-content-between mt-4">
                <Button variant="secondary" onClick={handleBack} disabled={step === 0}>
                  Back
                </Button>
                <Button variant="primary" onClick={() => handleNext(formik)}>
                  {step === steps.length - 1 ? 'Submit' : 'Next'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <SuccessModal show={showSuccessModal} onClose={handleModalClose} />
    </Row>
  );
};

export default StepperWizard;
